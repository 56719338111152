import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"
import { IndividualSurvey } from "../components/survey"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermCareInsuranceQuote = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <SEO
        title="Long Term Care Insurance Quote | Affordable Plans | LTCPG"
        ogDescription="Get your Long Term Care Insurance Quote. Just answer a few, quick questions. Corey Rieck and his team will contact you with information and education."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Long Term Care Insurance Quote</h1>
            <IndividualSurvey />
          </div>
        }
        textRight={
          <div>
            <img src="/images/AdobeStock_184356757.jpg" alt="Long Term Care Insurance Quote" />
          </div>
        }
      />
      <QuotePanel text="Hello World" person="John Doe" />
    </PageLayout>
  )
}

export default LongTermCareInsuranceQuote
